
import { timeouts } from "@evercam/ui"

import { TAGS_TYPES, PANO_VIEWER_TYPE } from "@evercam/3d/components/constants"
import MESSAGES_TYPES from "@evercam/shared/constants/iframeMessagesTypes"
import SnapshotEditor from "@/components/SnapshotEditor"
import { AutodeskConnectorType } from "@evercam/shared/constants/autodesk"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import CompareExportDialog from "@/components/CompareExportDialog"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import RecordingsDialog from "@/components/3dAppsDialogs/RecordingsDialog"
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import ChooseForgeModel from "@/components/3dAppsDialogs/ChooseForgeModel"
import ConnectToForgeViewer from "@/components/3dAppsDialogs/ConnectToForgeViewer"
import { mapStores } from "pinia"
import { useWeatherStore } from "@evercam/dashboard/stores/weather"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { useAccountStore } from "@/stores/account"
import { useConnectorStore } from "@/stores/connector"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  Connector,
  InfoPage,
} from "@evercam/shared/types"
import ContextMenu from "@/components/3dAppsDialogs/ContextMenu"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import axios from "axios"

export default {
  name: "BimViewer",
  meta: {
    pageId: AnalyticsEventPageId.bim,
  },
  components: {
    SnapshotEditor,
    FrameViewer,
    RecordingsDialog,
    CompareExportDialog,
    CreateUploadDialog,
    ContextMenu,
    ChooseForgeModel,
    ConnectToForgeViewer,
    EvercamLoadingAnimation,
  },
  mixins: [timeouts],
  middleware({ redirect, from, $permissions }) {
    const accountStore = useAccountStore()
    const projectStore = useProjectStore()
    // const connectorStore = useConnectorStore()

    if (!accountStore.token) {
      accountStore.redirectUrl = from.fullPath

      return redirect("/v2/users/signin")
    }

    if (!$permissions.project.has.BIMView()) {
      return redirect(`${projectStore.projectRoute}/info/${InfoPage.BIMView}`)
    }
  },
  data: () => ({
    recordingsCameraExid: undefined,
    gateReportCameraExid: undefined,
    isPanoViewEnabled: false,
    panoViewerMode: null,
    isEditingImage: false,
    currentSnapshot: null,
    cameraContextMenuInfo: null,
    compareOptions: {},
    ingestUploadType: INGEST_UPLOADS_TYPES.bimUploads,
    bimUploadObject: undefined,
    bimUploadDialog: false,
    initialQueryParams: {},
    token: null,
    autodeskConnectorsTypes: AutodeskConnectorType,
    autodeskDialog: false,
    askTokenDialog: false,
    loadingToken: true,
  }),
  head() {
    return {
      title: `${this.projectStore.selectedProjectName || "Evercam"} | BIM`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    ...mapStores(
      useWeatherStore,
      useProjectStore,
      useCameraStore,
      useAccountStore,
      useIngestFileUploaderStore,
      useConnectorStore
    ),
    firstCamera() {
      return this.projectStore.selectedProjectCameras[0] || {}
    },
    routeProjectExid() {
      return camelizeKeys(this.$route.params)?.projectExid
    },
    baseURL() {
      if (this.$permissions.project.has.BIMViewIsForge()) {
        return this.$config.public.forgeIframeUrl
      } else {
        return this.$config.public.iTwinIframeUrl
      }
    },
    params() {
      const params = {
        ...this.initialQueryParams,
        projectID: this.routeProjectExid,
        type: "site-view",
        unit: this.accountStore.unitSystem,
      }

      if (this.$permissions.project.has.BIMViewIsForge() && this.token) {
        params["token"] = this.token.token
      }

      return "?" + new URLSearchParams(params)
    },
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
    this.initialQueryParams = this.$route.query
    this.saveAnalyticsEvent("open")
    this.initAutodeskViewer()
  },
  methods: {
    async onMessageReceived(data) {
      const messageType = data?.type

      switch (messageType) {
        case MESSAGES_TYPES.analyticsEvent:
          this.saveAnalyticsEvent(data?.action)
          break
        case MESSAGES_TYPES.liveView:
          this.openRecordings(data?.cam)
          break

        case MESSAGES_TYPES.ready:
          this.sendProjectInfo(data?.origin)
          break
        case MESSAGES_TYPES.editSnapshot:
          if (!this.isEditingImage) {
            this.openImageEditor(data?.url)
          }
          break

        case MESSAGES_TYPES.weather:
          this.weatherStore.targetTimestamp = data.timestamp
          this.weatherStore.changeWeatherVisibility(true)
          break
        case MESSAGES_TYPES.cameraInfo:
          {
            const cameraInfoType = data?.data?.type

            if (cameraInfoType === TAGS_TYPES.aerialshot) {
              this.panoViewerMode = PANO_VIEWER_TYPE.aerialshot
              this.openPanoView(data.data)
            } else if (cameraInfoType === PANO_VIEWER_TYPE._360Path) {
              this.panoViewerMode = PANO_VIEWER_TYPE._360Path
              this.openPanoView(data.data)
            } else {
              // This case is for cameras, gate cameras & indoor cameras
              this.openCameraContextMenu(data.position, data.cam, data.data)
            }
          }
          break
        case MESSAGES_TYPES.createCompare:
          this.compareOptions = data.data
          this.$root.$emit("toggle-compare-dialogue")
          break
        case MESSAGES_TYPES.uploadBIM:
          await this.generateBimUploadLink()
          break
        case MESSAGES_TYPES.history:
          if (data.origin === this.$config.public.iTwinIframeUrl) {
            this.pushSearchParams(data.history)
          }
          break
        case MESSAGES_TYPES.loadForgeModel:
          if (this.connectorStore.isAutodeskConnected) {
            this.autodeskDialog = true
          } else {
            this.connectorStore.openConnectorPromptDialog(Connector.Autodesk)
          }
          break
        default:
          break
      }
    },
    openRecordings(cameraExid) {
      this.cameraContextMenuInfo = null
      this.recordingsCameraExid = null
      this.$setTimeout(() => {
        this.recordingsCameraExid = cameraExid
      })
    },
    saveAnalyticsEvent(action) {
      this.$analytics.saveEvent(`${action}_4d_view`)
    },
    async sendProjectInfo(origin) {
      const frameViewerRef = this.$refs.frameViewer // This could be Pano-iFrame, Itwin-iFrame or Forge-iFrame
      const frameViewerContainer = frameViewerRef?.$refs.container
      frameViewerContainer?.contentWindow?.postMessage(
        {
          type: MESSAGES_TYPES.info,
          cameras: this.projectStore.selectedProjectCameras.reduce(
            (acc, value) => {
              acc[value.id] = value

              return acc
            },
            {}
          ),
          user: {
            email: this.accountStore.email,
            name: this.accountStore.fullname,
          },
        },
        origin
      )
    },
    async openCameraContextMenu(position, cameraExid, data) {
      this.cameraContextMenuInfo = {
        x: position.x,
        y: position.y,
        camera: cameraExid,
        data,
      }
    },
    async generateBimUploadLink() {
      if (!this.projectStore.selectedProjectExid) {
        return
      }
      try {
        const response = await IngestApi.bim.createUpload(
          this.projectStore.selectedProjectExid,
          {
            uploadedBy: this.accountStore.email,
          }
        )

        this.bimUploadObject = {
          ...response,
          projectName: this.projectStore.selectedProject.name,
        }
        const uploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
          uploadFinished: false,
          uploadType: INGEST_UPLOADS_TYPES.bimUploads,
        }
        this.ingestFileUploaderStore.currentUploadStats = uploadStats
        this.ingestFileUploaderStore.uploadStats.push(uploadStats)
        this.bimUploadDialog = true
      } catch (error) {
        this.$notifications.error({
          text: "Could not get token ",
          error,
        })
      }
    },
    onBimUplaodDialogClosed() {
      this.ingestFileUploaderStore.currentBIMUploadStat = undefined
      this.bimUploadDialog = false
      this.bimUploadObject = undefined
    },
    openImageEditor(urlData) {
      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
        camera: this.firstCamera,
      }
      this.isEditingImage = true
    },
    openPanoView(metadata) {
      const markerData = metadata.pano.split("-")
      this.currentAerialShot = {
        date_id: markerData[0],
        floor: markerData[1],
        marker: markerData[2],
      }
      this.isPanoViewEnabled = true
      this.cameraContextMenuInfo = null
    },
    closePanoView() {
      this.isPanoViewEnabled = false
      this.panoViewerMode = null
    },
    pushSearchParams(parameters) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          ...parameters,
        },
      })
    },
    async initAutodeskViewer() {
      if (!this.$permissions.project.has.BIMViewIsForge()) {
        this.loadingToken = false

        return
      }

      let projects
      try {
        this.loadingToken = true
        projects = await axios.get(
          `${this.$config.public.firebaseDbLink}data/projects/forge/${this.projectStore.selectedProject.exid}.json`
        )
        this.token = await EvercamApi.autodesk.fetchViewerToken(
          this.projectStore.selectedProject.exid
        )
      } catch (e) {
        console.error(e)
      } finally {
        if (!projects?.data) {
          if (this.connectorStore.isAutodeskConnected) {
            this.autodeskDialog = true
          } else {
            this.connectorStore.openConnectorPromptDialog(Connector.Autodesk)
          }
        } else if (!projects?.data.isEvercamAccount && !this.token) {
          this.askTokenDialog = true
        } else {
          this.loadingToken = false
        }
      }
    },
    async selectModel(file) {
      if (!this.token) {
        this.askTokenDialog = true

        return
      }
      this.autodeskDialog = false
      await this.connectorStore.setForgeModelId(
        this.projectStore.selectedProject.exid,
        file
      )
      this.loadingToken = true
      setTimeout(() => (this.loadingToken = false), 500)
    },
  },
}
